"use client";

import { Transition } from "@headlessui/react";
import type { Dispatch, SetStateAction, SyntheticEvent } from "react";
import React, { useCallback, useEffect } from "react";
import dynamic from "next/dynamic";

const CodeBlock = dynamic(() => import("../CodeBlock"), { ssr: false });

const ToastSlideOver = ({
  isOpen,
  setIsOpen,
  afterLeave,
  title,
  response,
}: Readonly<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  title: string;
  response: string;
  afterLeave?: () => void;
}>): JSX.Element => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const closeSlideOver = useCallback(
    (clickEvent: SyntheticEvent<HTMLButtonElement | HTMLDivElement>) => {
      if ("preventDefault" in clickEvent) {
        clickEvent.preventDefault();
      }

      setIsOpen(false);
    },
    [setIsOpen],
  );

  useEffect(() => {
    document.body.addEventListener("keyup", handleKeyUp);

    return (): void => {
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const handleAfterLeave = useCallback((): void => {
    afterLeave?.();
  }, [afterLeave]);

  return (
    <Transition
      afterLeave={handleAfterLeave}
      className="absolute z-50"
      show={isOpen}
    >
      <div
        className="fixed inset-0 overflow-hidden"
        data-testid="toast::slideover"
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            className="fixed inset-0"
            enter="ease-in-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-150 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              aria-hidden="true"
              className="bg-ash/80 absolute inset-0"
              onClick={closeSlideOver}
            />
          </Transition.Child>
          <section
            aria-labelledby="slide-over-heading"
            className="absolute inset-y-0 right-0 flex max-w-full"
          >
            <Transition.Child
              enter="transition ease-in-out duration-75 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-75 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative h-full w-screen max-w-2xl p-4">
                <div className="rounded-highnote relative flex h-full flex-col overflow-y-scroll bg-white py-6">
                  <div className="absolute right-0 top-0 z-10 flex pr-2 pt-6 sm:-ml-4 sm:pr-4">
                    <button
                      className="hover:bg-bone focus:bg-ash flex h-10 w-10 items-center justify-center rounded-full"
                      data-testid="slide-over::close-panel"
                      onClick={closeSlideOver}
                      type="button"
                    >
                      <span className="sr-only">Close panel</span>
                      {/* Heroicon name: outline/x */}
                      <svg
                        aria-hidden="true"
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 18L18 6M6 6l12 12"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="pb-5">
                    <div className="relative px-4 pb-8 sm:px-8">
                      <h2
                        className="headline font-display pb-2 pr-6 text-xl"
                        id="slide-over-heading"
                      >
                        {title}
                      </h2>
                    </div>
                    <pre
                      className="text-xxs mx-6 overflow-x-scroll rounded-lg bg-black p-6 text-white"
                      data-testid="toast::slideover::response"
                    >
                      <CodeBlock language="json" value={response} />
                    </pre>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};

export default ToastSlideOver;
