import { CommonAppContextProvider } from "@bay1/data";
import { GraphiqlContextProvider } from "@bay1/data/ContextGraphiql";
import GlobalStyle from "@bay1/ui";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import type { AppProps as DefaultAppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { NormalizedCacheObject } from "@apollo/client";
import { Analytics } from "@vercel/analytics/react";
import { Toast } from "@bay1/ui/components/toasts";
import { ToastContextProvider } from "@bay1/ui/components/toasts/ToastContext";
import { ApolloProvider } from "@bay1/client";

import { LoginGate } from "../components/LoginGate";
import SignedIn from "../components/SignedIn";
import { removeSensitiveUrlParams } from "../lib/analytics";

import "../styles.css";
import "@bay1/ui/HelveticaNow.css";
import "@bay1/ui/spaceMono.css";
import "@bay1/ui/styles.css";

// eslint-disable-next-line fp/no-mutation
process.env.NEXTAUTH_URL = DASHBOARD_URL;

const PUBLIC_ROUTES = ["/auth/signin", "/invitations/[invitationCode]"];

type AppProps = DefaultAppProps<{
  session: Session;
  initialApolloState?: NormalizedCacheObject;
}>;

const Dashboard = ({
  Component,
  pageProps: { session, initialApolloState, ...pageProps },
}: AppProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <link href="/favicon.svg" rel="shortcut icon" />
      </Head>
      <GlobalStyle />
      {PUBLIC_ROUTES.includes(router.pathname) ? (
        <>
          <Component {...pageProps} />
          <Analytics
            beforeSend={(event) => {
              if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
                return null;
              }

              return {
                ...event,
                url: removeSensitiveUrlParams(event.url, router.pathname),
              };
            }}
          />
        </>
      ) : (
        <SessionProvider session={session}>
          <ToastContextProvider>
            <ApolloProvider initialState={initialApolloState}>
              <CommonAppContextProvider router={router}>
                <LoginGate>
                  <SignedIn>
                    <GraphiqlContextProvider>
                      <Component {...pageProps} />
                      <Analytics
                        beforeSend={(event) => {
                          if (
                            process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
                          ) {
                            return null;
                          }

                          return {
                            ...event,
                            url: removeSensitiveUrlParams(
                              event.url,
                              router.pathname,
                            ),
                          };
                        }}
                      />
                      <Toast />
                    </GraphiqlContextProvider>
                  </SignedIn>
                </LoginGate>
              </CommonAppContextProvider>
            </ApolloProvider>
          </ToastContextProvider>
        </SessionProvider>
      )}
    </>
  );
};

export default Dashboard;
