"use client";

import {
  type NormalizedCacheObject,
  ApolloProvider as DefaultApolloProvider,
} from "@apollo/client";

import { useApollo } from "./apollo";

export function ApolloProvider({
  initialState,
  children,
}: {
  initialState?: NormalizedCacheObject;
  children: React.ReactNode;
}) {
  const apolloClient = useApollo(initialState);

  return (
    <DefaultApolloProvider client={apolloClient}>
      {children}
    </DefaultApolloProvider>
  );
}
