import {
  CardProductVertical,
  PaymentCardNetwork,
} from "@bay1/sdk/generated/graphql";

export const REFRESH_TOKEN = "REFRESH-ACCESS-TOKEN";

export const CardFeatureMap: Record<string, string> = {
  CreditPaymentCardFinancialAccountFeature: "Credit Card",
  DebitPaymentCardFinancialAccountFeature: "Debit Card",
  DirectDepositFinancialAccountFeature: "Direct Deposit",
  JustInTimeFundingFinancialAccountFeature: "Just-in-Time Funding",
  NegativeBalanceReserveFinancialAccountFeature: "Negative Balance Reserve",
  PayrollAdvanceFinancialAccountFeature: "Payroll Advance",
  PayrollEmployerAdvanceFinancialAccountFeature: "Employer Payroll Advance",
  PointRewardFinancialAccountFeature: "Point Rewards",
  PrePaidPaymentCardFinancialAccountFeature: "Prepaid Card",
  ProductFundingFinancialAccountFeature: "Funds the Product",
  ProductReserveFinancialAccountFeature: "Reserve Account",
  SecuredCreditPaymentCardFinancialAccountFeature: "Secured Credit",
  FleetCardAccountFeature: "Fleet",
  CardFundingFinancialAccountFeature: "Card Funding",
  NonVerifiedFinancialAccountFeature: "Non-Verified",
  AchCapableFinancialAccountFeature: "ACH Capable",
  VendorProvidedBankFinancialAccountFeature: "Vendor Provided",
  CommercialCreditPayInFullCardAccountFeature: "Credit Pay-in-Full",
  OnDemandFundingFinancialAccountFeautre: "On-Demand Funding",
};

export const accountVerticals = [
  CardProductVertical.EARNED_WAGE_ACCESS,
  CardProductVertical.GENERAL_PURPOSE_RELOADABLE,
  CardProductVertical.CONSUMER_PREPAID,
  CardProductVertical.PAYROLL,
];

export const businessVerticals = [
  CardProductVertical.SECURED_COMMERCIAL_CREDIT,
  CardProductVertical.AP_INVOICE_AUTOMATION,
  CardProductVertical.COMMERCIAL_DEBIT,
  CardProductVertical.FLEET,
  CardProductVertical.COMMERCIAL_PREPAID,
  CardProductVertical.COMMERCIAL_CREDIT_PAY_IN_FULL,
];

export type CreateCardProductFormInput = {
  accountHolderType: "CONSUMER" | "COMMERCIAL";
  vertical: CardProductVertical;
  network: PaymentCardNetwork;
  name: string;
};
