import React, { useContext } from "react";
import { MARKETING_URL, DASHBOARD_URL } from "@bay1/ui/urlHelper";
import { CommonAppContext } from "@bay1/data";
import Link from "next/link";
import { PageLoader } from "@bay1/ui";

// pages/404.js
export default function Custom404(): JSX.Element {
  const { activeOrganization } = useContext(CommonAppContext);

  return activeOrganization ? (
    <div className="bg-marquee grid h-screen w-screen bg-black bg-cover bg-center bg-no-repeat">
      <div className="mx-auto self-center">
        <h1 className="text-center text-3xl text-white">Page does not exist</h1>
        <p className="mb-3 text-center text-lg text-gray-200">
          That page does not exist or you do not have access to it.
        </p>
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
            <div className="flex space-x-10">
              <Link
                href={{
                  pathname: String(MARKETING_URL),

                  query: {
                    activeOrganizationId: activeOrganization.id,
                  },
                }}
                passHref
                className="text-white underline hover:text-gray-300"
              >
                Homepage
              </Link>
              <Link
                href={{
                  pathname: `${MARKETING_URL}/docs`,

                  query: {
                    activeOrganizationId: activeOrganization.id,
                  },
                }}
                passHref
                className="text-white underline hover:text-gray-300"
              >
                API &amp; Documentation
              </Link>
              <Link
                href={`${DASHBOARD_URL}/organizations/${activeOrganization.id}/home`}
                passHref
                className="text-white underline hover:text-gray-300"
              >
                Dashboard
              </Link>
              <Link
                href={{
                  pathname: `${MARKETING_URL}/contact`,

                  query: {
                    activeOrganizationId: activeOrganization.id,
                  },
                }}
                passHref
                className="text-white underline hover:text-gray-300"
              >
                Support
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  ) : (
    <PageLoader />
  );
}
