import React from "react";

export const ErrorBox = ({
  message,
  onDismiss,
}: Readonly<{
  message: string;
  onDismiss?: () => void;
}>): JSX.Element => (
  <div className="rounded bg-red-50 p-2" data-testid="errorBox">
    <div className="flex">
      {!onDismiss && (
        <div className="shrink-0">
          {/* Heroicon name: solid/x-circle */}
          <svg
            aria-hidden="true"
            className="h-5 w-5 text-red-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              fillRule="evenodd"
            />
          </svg>
        </div>
      )}
      <div className="ml-1">
        <h3 className="text-xs" data-testid="errorBox::message">
          {message}
        </h3>
      </div>
      {onDismiss && (
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
              onClick={onDismiss}
              type="button"
            >
              <span className="sr-only">Dismiss</span>
              {/* Heroicon name: solid/x */}
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
);
